import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { LinearProgress } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";


const CustomerAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [values, setValue] = useState({
    name: null,
    phone: null,
    email: null,
  });

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();

    setSubmitting(true);
    setLoadingOverlayState(true);
    
    const url = `${settings().apiUrl}/api/customers`;
    const headers = getHeaders();
    const body = JSON.stringify({
      name: values.name,
      phone: values.phone,
      email: values.email,
    });

    axios
      .post(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        setLoadingOverlayState(false);
        const { data } = props;
        data.push(response.data.data);
        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Usuário adicionado com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: false,
        });
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  }

  return (
    <FormWrapper onSubmit={(event) => store(event)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Nome"}
            name={"name"}
            id={"name"}
            onChange={handleChange("name")}
            value={values.name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"E-mail"}
            name={"email"}
            id={"email"}
            onChange={handleChange("email")}
            value={values.email}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'(99) 99999-9999'}
            name={"phone"}
            id={"phone"}
            onChange={handleChange("phone")}
            value={values.phone}
          >
            <TextFieldWrapper label={"Telefone"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        {isSubmitting && <LinearProgress />}
        <Grid item xs={12}>
          <Box>
            <SaveButton
              type="submit"
              disabled={isSubmitting}
            />
          </Box>
        </Grid>
      </Grid>
    </FormWrapper>
  );
};
export default CustomerAdd;
