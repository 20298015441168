import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, makeStyles, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
// import EditButton from "../../components/Buttons/EditButton";
import AddButton from "../../components/Buttons/AddButton";
import DeleteButtonActivation from "../../components/Buttons/DeleteButtonActivation";

import CustomerAdd from "./CustomerAdd";
import CustomerEdit from "./CustomerEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


const Customer: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/customers`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }, []);

  const openEditDialog = async (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/customers/${rowData.id}`;
    let headers = getHeaders();

    let editDialogData = {};
    let customerGroups: any = [];
    let customerStores: any = [];
    let stores: any = [];

    await axios
      .get(url, { headers })
      .then((response) => {
        editDialogData = {...response.data.data};
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/groups/${rowData.id}`;
    headers = getHeaders();

    await axios
      .get(url, { headers })
      .then((response) => {
        customerGroups = {...response.data};
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/associated/stores/${rowData.id}`;
    headers = getHeaders();

    await axios
      .get(url, { headers })
      .then((response) => {
        customerStores = {...response.data};
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/stores`;
    headers = getHeaders();

    await axios
      .get(url, { headers })
      .then((response) => {
        stores = [...response.data.data];
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/groups/customer`;
    headers = getHeaders();

    await axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Editar Usuário',
          content: <CustomerEdit
            editKey={indexKey}
            editDialogData={editDialogData}
            customerGroups={customerGroups}
            customerStores={customerStores}
            stores={stores}
            groups={[...response.data]}
            setData={setData}
            data={data}
          />,
          maxWidth: "xl",
          transition: "regular",
        })
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const deleteItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/customers/toggle/enabled/${rowData.id}`;
    let headers = getHeaders();

    let is_enabled = 1;

    if(rowData.is_enabled === 1) {
      is_enabled = 0;
    }

    const body = JSON.stringify({
      is_enabled: is_enabled,
    });

    await axios
      .put(url, body, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customers`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Status do usuário editado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const addDialog = <CustomerAdd setData={setData} data={data} />;

  const columns = [
    {
      label: 'Nome',
      name: "name",
    },
    {
      label: "E-mail",
      name: "email",
    },
    {
      label: 'Telefone',
      name: "phone",
    },
    {
      label: 'Status',
      name: "is_enabled",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value == 1 ? 
              <Chip label="Ativo" className={classes.activeChip} />
              : 
              <Chip label="Inativo" className={classes.inactiveChip} />
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "is_enabled",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <DeleteButtonActivation
              onClick={() =>
                setConfirmationDialogState({
                  ...confirmationDialog,
                  open: true,
                  question: `Você tem certeza que deseja ${value == 1 ? 'desativar' : 'ativar'} o usuário ${
                    data[tableMeta.currentTableData[tableMeta.rowIndex].index].name
                  }?`,
                  action: () => deleteItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                  transition: "default",
                })
              }
              active={value}
            />
          </>
        ),
      },
    },
    {
      label: 'Editar',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <EditButton
            onClick={() =>
              openEditDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
          />
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Usuários'}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: 'Adicionar Usuário',
                  content: addDialog,
                  maxWidth: "xl",
                  transition: "regular",
                })
              }
            >
              {'Adicionar Usuário'}
            </AddButton>
          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Customer;
