import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, makeStyles, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
// import EditButton from "../../components/Buttons/EditButton";
import AddButton from "../../components/Buttons/AddButton";
import DeleteButton from "../../components/Buttons/DeleteButton";

import EmailAdd from "./EmailAdd";
import EmailEdit from "./EmailEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


const Email: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/customer/companies`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data.company_emails]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }, []);

  const openEditDialog = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/company/emails/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Editar E-mail',
          content: <EmailEdit
            editKey={indexKey}
            editDialogData={{...response.data.data}}
            setData={setData}
            data={data}
          />,
          maxWidth: "xl",
          transition: "regular",
        })
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );


  };

  const deleteItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/customer/company/emails/${rowData.id}`;
    let headers = getHeaders();

    await axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/companies`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data.company_emails]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: 'E-mail deletado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const addDialog = <EmailAdd setData={setData} data={data} />;

  const columns = [
    {
      label: 'Nome',
      name: "name",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: 'Deletar',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <DeleteButton
              onClick={() =>
                setConfirmationDialogState({
                  ...confirmationDialog,
                  open: true,
                  question: `Você tem certeza que deseja deletar o e-mail ${
                    data[tableMeta.currentTableData[tableMeta.rowIndex].index].email
                  }?`,
                  action: () => deleteItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                  transition: "default",
                })
              }
            />
          </>
        ),
      },
    },
    {
      label: 'Editar',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <EditButton
            onClick={() =>
              openEditDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
          />
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'E-mails'}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: 'Adicionar E-mail',
                  content: addDialog,
                  maxWidth: "sm",
                  transition: "regular",
                })
              }
            >
              {'Adicionar E-mail'}
            </AddButton>
          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Email;
