import React, { useState } from "react";

import { useRecoilState } from "recoil";

import { LinearProgress, MenuItem } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import { settings, locale, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";


const StoreAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
  companies: {}[];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [values, setValue] = useState({
    company_id: null,
    name: null,
    initials: null,
    corporate_name: null,
    tax_id: null,
    contact: null,
    phone: null,
    address: null,
    number: null,
    address2: null,
    district: null,
    city: null,
    province: null,
    postal_code: null,
  });

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();

    setSubmitting(true);
    setLoadingOverlayState(true);
    
    const url = `${settings().apiUrl}/api/customer/stores`;
    const headers = getHeaders();
    const body = JSON.stringify({
      name: values.name,
      initials: values.initials,
      corporate_name: values.corporate_name,
      tax_id: values.tax_id,
      contact: values.contact,
      phone: values.phone,
      address: values.address,
      number: values.number,
      address2: values.address2,
      district: values.district,
      city: values.city,
      province: values.province,
      postal_code: values.postal_code,
      is_enabled: false,
      country: 'Brazil',
    });

    axios
      .post(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        const { data } = props;
        data.push(response.data.data);
        props.setData([...data]);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Loja adicionada com sucesso. Aguarde a aprovação dos administradores para que as modificações tenham efeito.',
        });
        setDialogState({
          ...dialog,
          open: false,
        });    
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  }

  return (
    <FormWrapper onSubmit={(event) => store(event)}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Nome da Loja"}
            name={"name"}
            id={"name"}
            onChange={handleChange("name")}
            value={values.name}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Sigla"}
            name={"initials"}
            id={"initials"}
            onChange={handleChange("initials")}
            value={values.initials}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Razão Social"}
            name={"corporate_name"}
            id={"corporate_name"}
            onChange={handleChange("corporate_name")}
            value={values.corporate_name}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'99.999.999/9999-99'}
            name={"tax_id"}
            id={"tax_id"}
            onChange={handleChange("tax_id")}
            value={values.tax_id}
          >
            <TextFieldWrapper label={"CNPJ"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Responsável"}
            name={"contact"}
            id={"contact"}
            onChange={handleChange("contact")}
            value={values.contact}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'(99) 99999-9999'}
            name={"phone"}
            id={"phone"}
            onChange={handleChange("phone")}
            value={values.phone}
          >
            <TextFieldWrapper label={"Telefone"} required />
          </InputMaskWrapper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper />
      </Grid>
      <h4>Endereço:</h4>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Endereço"}
            name={"address"}
            id={"address"}
            onChange={handleChange("address")}
            value={values.address}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Número"}
            name={"number"}
            id={"number"}
            onChange={handleChange("number")}
            value={values.number}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Complemento"}
            name={"address2"}
            id={"address2"}
            onChange={handleChange("address2")}
            value={values.address2}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Bairro"}
            name={"district"}
            id={"district"}
            onChange={handleChange("district")}
            value={values.district}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextFieldWrapper
            label={"Cidade"}
            name={"city"}
            id={"city"}
            onChange={handleChange("city")}
            value={values.city}
            required            
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'aa'}
            name={"province"}
            id={"province"}
            onChange={handleChange("province")}
            value={values.province}
          >
            <TextFieldWrapper label={"UF"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <InputMaskWrapper
            mask={'99.999-999'}
            name={"postal_code"}
            id={"postal_code"}
            onChange={handleChange("postal_code")}
            value={values.postal_code}
          >
            <TextFieldWrapper label={"CEP"} required />
          </InputMaskWrapper>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        {isSubmitting && <LinearProgress />}
        <Grid item xs={12}>
          <Box>
            <SaveButton
              type="submit"
              disabled={isSubmitting}
            />
          </Box>
        </Grid>
      </Grid>
    </FormWrapper>
  );
};
export default StoreAdd;
