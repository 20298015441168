import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Box, Grid, MenuItem, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";

import {
  loadingOverlayState,
  handleErrorState,
} from "../../GlobalAtoms";
import FormWrapper from "../../components/Form/FormWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import HandleChange from "../../components/Form/HandleChange";
import ExportButton from "../../components/Buttons/ExportButton";

const ExportMaster: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [stores, setStores] = useState<any>([]);
  const [values, setValue] = useState({
    store_id: null,
    start_date: null,
    finish_date: null,
  });

  // Recoil
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars

  useEffect(() => {
    let headers = getHeaders();    
    let url = `${settings().apiUrl}/api/customer/stores`;

    axios
      .get(url, { headers })
      .then((response) => {
        setLoading(false);
        setStores([...response.data.data]);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }, []);

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const exportXls = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/orders/export/master`;
    const headers = getHeaders();
    const body = JSON.stringify({
      store_id: values.store_id,
      start_date: values.start_date,
      finish_date: values.finish_date,
    });

    axios
      .post(url, body, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        window.open(`${settings().apiUrl}${response.data}`);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Exportação Master'}</TitleHeader>}
          button={<></>}
        />
        <FormWrapper onSubmit={(event) => exportXls(event)}>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <InputMaskWrapper
                mask={'99/99/9999'}
                name={"start_date"}
                id={"start_date"}
                onChange={handleChange("start_date")}
                value={values.start_date}
                required
              >
                <TextFieldWrapper label={"Data Inicial"} required />
              </InputMaskWrapper>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputMaskWrapper
                mask={'99/99/9999'}
                name={"finish_date"}
                id={"finish_date"}
                onChange={handleChange("finish_date")}
                value={values.finish_date}
                required
              >
                <TextFieldWrapper label={"Data Final"} required />
              </InputMaskWrapper>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"Loja"}
                name={"store_id"}
                id={"store_id"}
                onChange={handleChange("store_id")}
                value={values.store_id}
                select
              >
                {stores.map((store: any) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name} - {store.initials}
                  </MenuItem>
                ))}
              </TextFieldWrapper>
            </Grid>
            <Grid item xs={12}>
              <DividerWrapper />
            </Grid>
            <Grid item xs={12}>
              <Box>
                <ExportButton
                  type="submit"
                ><>Exportar</></ExportButton>
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>

        <DividerWrapper />
      </ContentWrapper>
    </>
  );
};

export default ExportMaster;
