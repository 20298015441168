import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { makeStyles, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  handleErrorState,
} from "../../GlobalAtoms";

import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


const Log: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);

  useEffect(() => {
    let url = `${settings().apiUrl}/api/customer/logs`;
    let headers = getHeaders();

    axios
    .get(url, { headers })
    .then((response) => {
      setData([...response.data.data]);
      setLoading(false);
    })
    .catch((error) =>
      setHandleError({ error: true, response: error.response })
    );
  }, []);


  const columns = [
    {
      label: 'Ação',
      name: "name",
    },
    {
      label: "Usuário",
      name: "customer_name",
    },
    {
      label: "Data",
      name: "created_at",
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Log de Acesso'}</TitleHeader>}
          button={<></>}
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Log;
