import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Button, Chip, makeStyles, SvgIconTypeMap, withStyles } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
// import EditButton from "../../components/Buttons/EditButton";
import AddButton from "../../components/Buttons/AddButton";
import DeleteButton from "../../components/Buttons/DeleteButton";

import SellingAdd from "./SellingAdd";
import SellingEdit from "./SellingEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green, teal, blue } from "@material-ui/core/colors";
import { Description, Error, GetApp, Visibility } from "@material-ui/icons";
import OrderConfirm from "./OrderConfirm";

const useStyles = makeStyles({
  openChip: {
    background: green[600],
    color: '#fff',
  },
  approvedChip: {
    background: blue[700],
    color: '#fff',
  },
  finalizededChip: {
    background: teal[700],
    color: '#fff',
  },
  cancelChip: {
    background: red[700],
    color: '#fff',
  },
});

const ConfirmButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const ViewButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: teal[600],
    "&:hover": {
      backgroundColor: teal[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);



const Selling: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [company, setCompany] = useState<any>({});
  const [stores, setStores] = useState<any>([]);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    let url = `${settings().apiUrl}/api/customer/orders/selling`;
    let headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/companies`;

    axios
      .get(url, { headers })
      .then((response) => {
        setLoading(false);
        setCompany({...response.data.data});
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
    
    url = `${settings().apiUrl}/api/customer/selling/stores`;

    axios
      .get(url, { headers })
      .then((response) => {
        setLoading(false);
        setStores({...response.data.data});
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
    }, []);

  const openEditDialog = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/orders/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Visualizar Pedido',
          content: <SellingEdit
            editKey={indexKey}
            editDialogData={{...response.data.data}}
            setData={setData}
            data={data}
          />,
          maxWidth: "xl",
          transition: "regular",
        })
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );


  };

  const openConfirmDialog = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/orders/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        setDialogState({
          ...dialog,
          open: true,
          title: 'Confirmar Entrega de Pedido',
          content: <OrderConfirm
            editKey={indexKey}
            editDialogData={{...response.data.data}}
            setData={setData}
            data={data}
          />,
          maxWidth: "sm",
          transition: "regular",
        })

      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const exportOrder = (rowData: {
    [key: string]: any
  }, indexKey: number) => {
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/orders/export/${rowData.id}`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setLoadingOverlayState(false);
        window.open( `${settings().cdnUrl}/tmp/${response.data}`);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };
  
  const deleteItem = async (rowData: {
    [key: string]: any
  }) => {
    setLoadingOverlayState(true);

    let url = `${settings().apiUrl}/api/customer/orders/status/cancel/${rowData.id}`;
    let headers = getHeaders();

    const body = JSON.stringify({
      status: 3,
    });

    await axios
      .put(url, body, { headers })
      .then((response) => {
        setSnackBar({
          open: true,
          type: "success",
          message: 'Pedido cancelado com sucesso.',
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/customer/orders/selling`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const addDialog = <SellingAdd setData={setData} data={data} company={company} stores={stores} />;

  const columns = [
    {
      label: 'N.º',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value}&nbsp;
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].exception === 1 && 
              <Error style={{ fontSize: 16, color: "#d32f2f" }} />
            }
          </>
        ),
      },
    },
    {
      label: "Sigla",
      name: "store_initials",
    },
    {
      label: 'Data',
      name: "created_at",
    },
    {
      label: 'Data de Entrega',
      name: "delivery_date",
    },
    {
      label: 'Usuário',
      name: "customer_name",
    },
    {
      label: 'Solicitado',
      name: "requested_value",
    },
    {
      label: 'Entrega',
      name: "coin_delivery_fee_paid",
    },
    {
      label: 'Por Milheiro',
      name: "coin_per_thousand_fee_paid",
    },
    {
      label: 'Status',
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value == 0 &&  <Chip label="Aberto" className={classes.openChip} /> }
            {value == 1 &&  <Chip label="Aprovado" className={classes.approvedChip} /> }
            {value == 2 &&  <Chip label="Finalizado" className={classes.finalizededChip} /> }
            {value == 3 &&  <Chip label="Cancelado" className={classes.cancelChip} /> }
          </>
        ),
      },
    },
    {
      label: 'Entrega',
      name: "confirmed",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].confirmed === 1 && data[tableMeta.currentTableData[tableMeta.rowIndex].index].confirmed_date}
            {(data[tableMeta.currentTableData[tableMeta.rowIndex].index].confirmed !== 1 && (data[tableMeta.currentTableData[tableMeta.rowIndex].index].status === '1' || data[tableMeta.currentTableData[tableMeta.rowIndex].index].status === '2')) && 
              <ConfirmButton
                onClick={() =>
                  openConfirmDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
                }
                variant="contained" size="small"
              >
                Confirmar
              </ConfirmButton>
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {data[tableMeta.currentTableData[tableMeta.rowIndex].index].status !== '3' &&
              <DeleteButton
                onClick={() =>
                  setConfirmationDialogState({
                    ...confirmationDialog,
                    open: true,
                    cancelText: 'fechar',
                    confirmationText: 'cancelar pedido',
                    question: `Você tem certeza que deseja cancelar o pedido N.º ${
                      data[tableMeta.currentTableData[tableMeta.rowIndex].index].id
                    }?`,
                    action: () => deleteItem(data[tableMeta.currentTableData[tableMeta.rowIndex].index]),
                    transition: "default",
                  })
                }
              />
            }
          </>
        ),
      },
    },
    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <ViewButton
            onClick={() =>
              openEditDialog(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
            }
            variant="contained" size="small"
          >
            <Visibility style={{ fontSize: 18 }} />
          </ViewButton>
        ),
      },
    },

    {
      label: ' ',
      name: "id",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: any,
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {/*<ConfirmButton
              onClick={() =>
                exportOrder(data[tableMeta.currentTableData[tableMeta.rowIndex].index], tableMeta.currentTableData[tableMeta.rowIndex].index)
              }
              variant="contained" size="small"
            >
              <GetApp style={{ fontSize: 18 }} />
            </ConfirmButton>*/}
            {(data[tableMeta.currentTableData[tableMeta.rowIndex].index].status === '2' && data[tableMeta.currentTableData[tableMeta.rowIndex].index].image !== null) &&
              <ConfirmButton
                variant="contained"
                size="small"
                onClick={() =>
                  window.open( `${settings().apiUrl}/storage/${data[tableMeta.currentTableData[tableMeta.rowIndex].index].image}`)
                }
              >
                <Description style={{ fontSize: 18 }} />
              </ConfirmButton>
            }

          </>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Aquisição de Troco'}</TitleHeader>}
          button={
            <AddButton
              onClick={() =>
                setDialogState({
                  ...dialog,
                  open: true,
                  title: 'Novo Pedido - Aquisição de Troco',
                  content: addDialog,
                  maxWidth: "xl",
                  transition: "regular",
                })
              }
            >
              {'Fazer Novo Pedido'}
            </AddButton>
          }
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Selling;
