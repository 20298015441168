import React from "react";

// @material-ui components
import { Button, withStyles } from "@material-ui/core";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import { red, green } from "@material-ui/core/colors";

const RedButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const GreenButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    minWidth: "0 !important",
    padding: "8px 10px !important",
  },
}))(Button);

const DeleteButtonActivation: React.FC<{
  onClick: () => void;
  active: any;
}> = (props) => {
  if(props.active === 1) {
    return (
      <RedButton variant="contained" size="small" {...props}>
        <PowerSettingsNew style={{ fontSize: 18 }} /> {props.children}
      </RedButton>
    );
  } else {
    return (
      <GreenButton variant="contained" size="small" {...props}>
        <PowerSettingsNew style={{ fontSize: 18 }} /> {props.children}
      </GreenButton>
    );
  }
  
}

export default DeleteButtonActivation;
