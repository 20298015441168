import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, makeStyles, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import TitleHeader from "../../components/TitleHeader";

import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import FormWrapper from "../../components/Form/FormWrapper";
import DividerWrapper from "../../components/DividerWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import ClientAdd from "./ClientAdd";
import ClientEdit from "./ClientEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";
import EditButton from "../../components/Buttons/EditButton";
import { red, green } from "@material-ui/core/colors";

const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


const Client: React.FC<{
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}> = () => {
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [values, setValue] = useState({
    id: null,
    name: null,
    initials: null,
    corporate_name: null,
    tax_id: null,
    contact: null,
    phone: null,
    address: null,
    number: null,
    address2: null,
    district: null,
    city: null,
    province: null,
    postal_code: null,
    coin_is_enabled: null,
    coin_delivery_fee: null,
    coin_per_thousand_fee: null,
    cash_is_enabled: null,
    cash_delivery_fee: null,
    is_enabled: null,
    country: null,
  });

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/customer/companies`;
    const headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setValue({...response.data.data});
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  }, []);


  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{'Dados da Empresa'}</TitleHeader>}
          button={
            <></>
          }
        />
        <DividerWrapper />
        <Grid container spacing={3} style={{padding: 25}}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Nome"}
              name={"name"}
              id={"name"}
              value={values.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Sigla"}
              name={"initials"}
              id={"initials"}
              value={values.initials}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Razão Social"}
              name={"corporate_name"}
              id={"corporate_name"}
              value={values.corporate_name}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"CNPJ"}
              name={"tax_id"}
              id={"tax_id"}
              value={values.tax_id}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Responsável"}
              name={"contact"}
              id={"contact"}
              value={values.contact}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Telefone"}
              name={"phone"}
              id={"phone"}
              value={values.phone}
              required            
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3} style={{padding: 25}}>
          <Grid item xs={12}>
            <h4>Endereço:</h4>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Endereço"}
              name={"address"}
              id={"address"}
              value={values.address}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Número"}
              name={"number"}
              id={"number"}
              value={values.number}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Complemento"}
              name={"address2"}
              id={"address2"}
              value={values.address2}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Bairro"}
              name={"district"}
              id={"district"}
              value={values.district}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Cidade"}
              name={"city"}
              id={"city"}
              value={values.city}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"UF"}
              name={"province"}
              id={"province"}
              value={values.province}
              required            
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"CEP"}
              name={"postal_code"}
              id={"postal_code"}
              value={values.postal_code}
              required            
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DividerWrapper />
        </Grid>
        <Grid container spacing={3} style={{padding: 25}}>
          <Grid item xs={12}>
            <h4>Serviços Disponíveis</h4>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckboxWrapper
                  label={"Aquisição de Troco"}
                  name={"coin_is_enabled"}
                  id={"coin_is_enabled"}
                  value={values.coin_is_enabled}
                  checked={values.coin_is_enabled}
                  required            
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete"}
                  name={"coin_delivery_fee"}
                  id={"coin_delivery_fee"}
                  value={values.coin_delivery_fee}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Custo do Milheiro"}
                  name={"coin_per_thousand_fee"}
                  id={"coin_per_thousand_fee"}
                  value={values.coin_per_thousand_fee}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CheckboxWrapper
                  label={"Recolhimento de Cédulas"}
                  name={"cash_is_enabled"}
                  id={"cash_is_enabled"}
                  value={values.cash_is_enabled}
                  checked={values.cash_is_enabled}
                  required            
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextFieldWrapper
                  label={"Valor do Frete"}
                  name={"cash_delivery_fee"}
                  id={"cash_delivery_fee"}
                  value={values.cash_delivery_fee}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
    </>
  );
};

export default Client;
