import React, { useEffect, useState } from "react";

import axios from "axios";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { settings, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";
import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import SaveButton from "../../components/Buttons/SaveButton";
import { useRecoilState } from "recoil";
import {
  dialogState,
  handleErrorState,
  snackBarState,
  loadingOverlayState,
} from "../../GlobalAtoms";
import { LinearProgress, makeStyles } from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";
import CheckboxWrapper from "../../components/Form/CheckboxWrapper";


const useStyles = makeStyles({
  inactiveChip: {
    background: red[700],
    color: '#fff',
  },
  activeChip: {
    background: green[600],
    color: '#fff',
  },
});


// @material-ui components

const CustomerEdit = (props: any) => {
  const classes = useStyles();

  const [values, setValue] = useState({
    id: props.editDialogData.id,
    name: props.editDialogData.name,
    phone: props.editDialogData.phone,
    email: props.editDialogData.email,
  });

  const [groups, setGroups] = useState(props.groups);
  const [checkedGroups, setCheckedGroups] = useState<any>(props.customerGroups);

  const [stores, setStores] = useState(props.stores);
  const [checkedStores, setCheckedStores] = useState<any>(props.customerStores);

  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  
  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };
  
  const handleChangeGroup = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setCheckedGroups({ ...checkedGroups, [data.name]: data.value });
  };
  
  const handleChangeStore = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setCheckedStores({ ...checkedStores, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customers/${props.editDialogData.id}`;
    const headers = getHeaders();

    const dataSave = JSON.stringify({
      id: values.id,
      name: values.name,
      phone: values.phone,
      email: values.email,
    });

    axios
      .put(url, dataSave, { headers })
      .then((response) => {
        const { data } = props;
        data[props.editKey] = {
          id: values.id,
          name: values.name,
          phone: values.phone,
          email: values.email,
        };

        props.setData([...data]);

        setSnackBar({
          open: true,
          type: "success",
          message: 'Usuário editado com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  const storeGroup = (event: any) => {
    event.preventDefault();
  
    if(props.editDialogData.id === 1) {
      setSnackBar({
        open: true,
        type: "error",
        message: 'Usuário administrador não pode ter grupos editados',
      });
    } else {
      setLoadingOverlayState(true);
  
      const url = `${settings().apiUrl}/api/customer/groups/${props.editDialogData.id}`;
      const headers = getHeaders();
  
      const dataSave = JSON.stringify(checkedGroups);
  
      axios
        .put(url, dataSave, { headers })
        .then((response) => {
          setSnackBar({
            open: true,
            type: "success",
            message: 'Grupos de usuário editados com sucesso',
          });
          setSubmitting(false);
          setLoadingOverlayState(false);
        })
        .catch((error) => {
          setLoadingOverlayState(false);
          setSubmitting(false);
          setHandleError({ error: true, response: error.response })
        });
    }
  };

  const storeStore = (event: any) => {
    event.preventDefault();
    setLoadingOverlayState(true);

    const url = `${settings().apiUrl}/api/customer/associated/stores/${props.editDialogData.id}`;
    const headers = getHeaders();

    const dataSave = JSON.stringify(checkedStores);

    axios
      .put(url, dataSave, { headers })
      .then((response) => {
        setSnackBar({
          open: true,
          type: "success",
          message: 'Lojas do usuário editadas com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  };

  return (
    <>
      <FormWrapper onSubmit={(event) => store(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"Nome"}
              name={"name"}
              id={"name"}
              onChange={handleChange("name")}
              value={values.name}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <TextFieldWrapper
              label={"E-mail"}
              name={"email"}
              id={"email"}
              onChange={handleChange("email")}
              value={values.email}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <InputMaskWrapper
              mask={'(99) 99999-9999'}
              name={"phone"}
              id={"phone"}
              onChange={handleChange("phone")}
              value={values.phone}
            >
              <TextFieldWrapper label={"Telefone"} required />
            </InputMaskWrapper>
          </Grid>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          {isSubmitting && <LinearProgress />}
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
      <FormWrapper onSubmit={(event) => storeGroup(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          <Grid item xs={12}>
            <h4>Editar Grupos de Permissão</h4>
          </Grid>
          {
            groups.map((group: any) => {
              return(
                <Grid item xs={12} sm={6} lg={4}>
                  <CheckboxWrapper
                    label={group.name}
                    name={group.id}
                    id={group.id}
                    onChange={handleChangeGroup(group.id)}
                    value={checkedGroups[group.id]}
                    checked={checkedGroups[group.id]}
                  />
                </Grid>
              );
            })
          }
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          {isSubmitting && <LinearProgress />}
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
      <FormWrapper onSubmit={(event) => storeStore(event)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          <Grid item xs={12}>
            <h4>Editar Lojas</h4>
          </Grid>
          {
            stores.map((store: any) => {
              return(
                <Grid item xs={12} sm={6} lg={4}>
                  <CheckboxWrapper
                    label={store.name}
                    name={store.id}
                    id={store.id}
                    onChange={handleChangeStore(store.id)}
                    value={checkedStores[store.id]}
                    checked={checkedStores[store.id]}
                  />
                </Grid>
              );
            })
          }
          <Grid item xs={12}>
            <DividerWrapper />
          </Grid>
          {isSubmitting && <LinearProgress />}
          <Grid item xs={12}>
            <Box>
              <SaveButton
                type="submit"
                disabled={isSubmitting}
              />
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
    </>
  );
};

export default CustomerEdit;