import React from "react";

// @material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import GetApp from "@material-ui/icons/GetApp";
import green from "@material-ui/core/colors/green";
import { locale } from "../../settings";

const ColorButton = withStyles(() => ({
  root: {
    color: "#fff",
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },
    width: "100%",
    padding: "8px 12px !important",
  },
}))(Button);

const ExportButton: React.FC<{
  children?: JSX.Element | string;
  disabled?: boolean;
  type?: string;
  onClick?: () => void;
}> = (props: any) => (
  <ColorButton variant="contained" {...props}>
    <GetApp style={{ fontSize: 18, marginRight: 10 }} />
    {props.children && <> {props.children}</>}
  </ColorButton>
);

export default ExportButton;
