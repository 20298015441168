import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";

import { LinearProgress } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";

import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import TitleHeader from "../../components/TitleHeader";


import { settings, locale, getHeaders } from "../../settings";
import TextFieldWrapper from "../../components/Form/TextFieldWrapper";

import FormWrapper from "../../components/Form/FormWrapper";
import HandleChange from "../../components/Form/HandleChange";
import DividerWrapper from "../../components/DividerWrapper";
import InputMaskWrapper from "../../components/Form/InputMaskWrapper";


const Account: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
}> = (props: any) => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] = useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  const [isSubmitting, setSubmitting] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const [loading, setLoading] = useState<boolean>(false);

  const [values, setValue] = useState<any>({
    name: null,
    phone: null,
    email: null,
  });

  const [passwordReset, setPasswordReset] = useState({
    old_password: null,
    new_password: null,
    password_confirmation: null,
  });


  useEffect(() => {
    let url = `${settings().apiUrl}/api/customer/account`;
    let headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setValue({...response.data});
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

  }, []);
  

  const handleChange = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setValue({ ...values, [data.name]: data.value });
  };

  const handleChangePassword = (name: string) => (event: any) => {
    const data = HandleChange(name, event);
    setPasswordReset({ ...passwordReset, [data.name]: data.value });
  };

  const store = (event: any) => {
    event.preventDefault();

    setSubmitting(true);
    setLoadingOverlayState(true);
    
    const url = `${settings().apiUrl}/api/customer/account`;
    const headers = getHeaders();
    const body = JSON.stringify({
      name: values.name,
      phone: values.phone,
      email: values.email,
    });

    axios
      .put(url, body, { headers })
      .then((response: { data: { data: {} } }) => {
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: 'Usuário editado com sucesso',
        });
        setSubmitting(false);
        setLoadingOverlayState(false);
      })
      .catch((error) => {
        setLoadingOverlayState(false);
        setSubmitting(false);
        setHandleError({ error: true, response: error.response })
      });
  }

  const changePassword = (event: any) => {
    event.preventDefault();

    if(passwordReset.new_password === passwordReset.password_confirmation) {
      setSubmitting(true);
      setLoadingOverlayState(true);
      
      const url = `${settings().apiUrl}/api/customer/account/change/password`;
      const headers = getHeaders();
      const body = JSON.stringify({
        old_password: passwordReset.old_password,
        new_password: passwordReset.new_password,
      });
  
      axios
        .put(url, body, { headers })
        .then((response: { data: { data: {} } }) => {
          setLoadingOverlayState(false);
          setSnackBar({
            open: true,
            type: "success",
            message: 'Senha editada com sucesso',
          });
          setSubmitting(false);
          setLoadingOverlayState(false);
        })
        .catch((error) => {
          setLoadingOverlayState(false);
          setSubmitting(false);
          setHandleError({ error: true, response: error.response })
        });
    } else {
      setSnackBar({
        open: true,
        type: "error",
        message: 'A nova senha e a confirmação precisam ser iguais',
      });
    }
  }

  return (
    <ContentWrapper loading={loading}>
      <MainHeader
        title={<TitleHeader>{'Minha Conta'}</TitleHeader>}
        button={<></>}
      />
      <DividerWrapper />
      <div style={{padding: 16}}>
        <FormWrapper onSubmit={(event) => store(event)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"Nome"}
                name={"name"}
                id={"name"}
                onChange={handleChange("name")}
                value={values.name}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"E-mail"}
                name={"email"}
                id={"email"}
                onChange={handleChange("email")}
                value={values.email}
                required            
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <InputMaskWrapper
                mask={'(99) 99999-9999'}
                name={"phone"}
                id={"phone"}
                onChange={handleChange("phone")}
                value={values.phone}
              >
                <TextFieldWrapper label={"Telefone"} required />
              </InputMaskWrapper>
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12}>
              <Box>
                <SaveButton
                  type="submit"
                  disabled={isSubmitting}
                />
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>
        <br />
        <DividerWrapper />
        <h4>Alteração de Senha</h4>
        <FormWrapper onSubmit={(event) => changePassword(event)}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"Senha Atual"}
                name={"old_password"}
                id={"old_password"}
                onChange={handleChangePassword("old_password")}
                value={passwordReset.old_password}
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"Nova Senha"}
                name={"new_password"}
                id={"new_password"}
                onChange={handleChangePassword("new_password")}
                value={passwordReset.new_password}
                required
                type="password"            
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextFieldWrapper
                label={"Confirmação de Senha"}
                name={"password_confirmation"}
                id={"password_confirmation"}
                onChange={handleChangePassword("password_confirmation")}
                value={passwordReset.password_confirmation}
                required
                type="password"            
              />
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12}>
              <Box>
                <SaveButton
                  type="submit"
                  disabled={isSubmitting}
                />
              </Box>
            </Grid>
          </Grid>
        </FormWrapper>
      </div>
    </ContentWrapper>

  );
};
export default Account;
