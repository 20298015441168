import {
  AccountBox as AccountBoxIcon,
  Description as DescriptionIcon,
} from "@material-ui/icons";

// Pages
import Client from "./pages/Client/Client";
import Store from "./pages/Store/Store";
import Email from "./pages/Email/Email";
import Selling from "./pages/Order/Selling";
import Acquiring from "./pages/Order/Acquiring";
import Exception from "./pages/Order/Exception";
import ExportMaster from "./pages/Order/ExportMaster";
import ExportStore from "./pages/Order/ExportStore";
import Account from "./pages/Customer/Account";
import Customer from "./pages/Customer/Customer";
import Log from "./pages/Log/Log";

/**
 * Sets all routes used in dashboard. All routes comes with title, icon and the page's component.
 * Display parameter can be set as 'menu' for main left menu or 'avatar' for avatar's menu
 * All routes for dashboard should be set here, even if it won't be displayed in any menu
 */

const dashboardRoutes = [
  {
    path: "/account",
    label: 'Minha Conta',
    icon: AccountBoxIcon,
    component: Account,
    layout: "/dashboard",
    display: "avatar",
  },
  {
    menuGroup: "pedidos",
    label: "Pedidos",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/selling",
        label: "Aquisição de Troco",
        component: Selling,
      },
      {
        path: "/acquring",
        label: "Recolhimento de Cédulas",
        component: Acquiring,
      },
      {
        path: "/exception",
        label: "Pedidos de Emergência",
        component: Exception,
      },
      {
        path: "/statistic/master",
        label: "Relatório Master",
        component: ExportMaster,
      },
      {
        path: "/statistic/store",
        label: "Relatório Loja",
        component: ExportStore,
      },
    ],
  },
  {
    menuGroup: "empresa",
    label: "Empresa",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/client",
        label: "Dados da Empresa",
        component: Client,
      },
      {
        path: "/stores",
        label: "Lista de Lojas",
        component: Store,
      },
      {
        path: "/emails",
        label: "E-mails de alerta",
        component: Email,
      },
    ],
  },
  {
    menuGroup: "usuarios",
    label: "Usuarios",
    icon: DescriptionIcon,
    layout: "/dashboard",
    display: "menu",
    links: [
      {
        divider: true,
      },
      {
        path: "/users",
        label: "Lista de Usuários",
        component: Customer,
      },
      {
        path: "/logs",
        label: "Log de Acesso",
        component: Log,
      },
    ],
  },
];

export default dashboardRoutes;
