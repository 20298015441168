import getLocale from "./i18n/pt-BR";

interface Headers {
  "Content-Type": "application/json";
  Accept: "application/json";
  "X-Store"?: string;
  "X-Company"?: string;
  Authorization?: string;
  credentials?: boolean;
  "X-XSRF-TOKEN"?: string;
}

const settings = () => ({
  i18n: "pt-BR",
  apiUrl: "https://api.zinnerlog.com.br",
  // apiUrl: "http://127.0.0.1:8000",
  cdnUrl: "https://cdn.zinnerlog.com.br",
  // cdnUrl: "http://127.0.0.1:8000",
  // apiUrl: "http://127.0.0.1:8000",
});

const locale = getLocale();

const materialTableSettings = () => ({
  localization: locale.materialTable,
  style: { border: "none", boxShadow: "none" },
});

const getHeaders = () => {
  let token = window.localStorage.getItem("ucode");

  const headers: Headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  if (token) {
    token = token.substring(1, token.length - 1);
    headers.Authorization = `Bearer ${token}`;
  }


  return headers;
};

const headers = getHeaders();

const drawerWidth = 280;

export {
  settings,
  locale,
  materialTableSettings,
  getHeaders,
  headers,
  drawerWidth,
};
